import request from '@/utils/request'

export default {
  fetchProducerList(data) {
    return request({
      url: `/producer/list`,
      method: 'post',
      data
    })
  },
  fetchProducerCodeList(data) {
    return request({
      url: `/producer/listAllCode`,
      method: 'post',
      data
    })
  },
  createProducer(data) {
    return request({
      url: '/producer/add',
      method: 'post',
      data
    })
  },
  editProducer(data) {
    return request({
      url: `/producer/edit`,
      method: 'POST',
      data
    })
  },
  // 更新生产商状态
  updateProducerStatus(data) {
    return request({
      url: `/producer/updateStatus`,
      method: 'POST',
      data
    })
  },
  fetchFabricNew() {
    return request({
      url: `/fsc/basic/fabric`,
      method: 'GET'
    })
  },
  // 生产单
  fetchProducerOrderData(data) {
    return request({
      url: `/fsc/producerOrder/commonData`,
      method: 'post',
      data
    })
  },
  fetchProducerOrderList(data) {
    return request({
      url: `/fsc/producerOrder/commonList`,
      method: 'post',
      data
    })
  },
  submitFinishTask(data) {
    return request({
      url: '/fsc/producerTask/finishTask',
      method: 'post',
      data
    })
  },
  getTaskListByIds(data) {
    return request({
      url: '/fsc/producerTask/listTaskByIdList',
      method: 'post',
      data
    })
  },
  calTotalMeter(data) {
    return request({
      url: '/fsc/producerTask/calTotalMeter',
      method: 'post',
      data
    })
  }
}

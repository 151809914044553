import Layout from '@/layout/Layout'

const productionRouter = {
  path: '/production',
  component: Layout,
  name: 'Production',
  redirect: '/production/order/list',
  meta: {
    title: 'production',
    icon: ''
  },
  children: [
    {
      path: 'order/list',
      component: () => import('@/views/production/order/list'),
      name: 'productionOrderList',
      meta: { title: 'productionOrderList' }
    },
    {
      path: 'batch/list',
      component: () => import('@/views/production/batch/list'),
      name: 'ProductionBatchList',
      meta: { title: 'productionBatchList' }
    }
  ]
}
export default productionRouter

<template>
  <div class="">
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#222222"
      text-color="#B1B1B1"
      active-text-color="#FFFFFF"
      menu-trigger="hover"
      router>
      <template v-for="navItem in navList">
        <el-menu-item v-if="navItem.subMenu.length === 0" :key="navItem.path" :index="navItem.path">{{
          navItem.label
        }}</el-menu-item>
        <el-submenu v-else :index="navItem.path" :key="navItem.path">
          <template slot="title">{{ navItem.label }}</template>
          <el-menu-item v-for="subNavItem in navItem.subMenu" :index="subNavItem.path" :key="subNavItem.path">{{
            subNavItem.label
          }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  watch: {},
  mounted() {},
  computed: {
    navList() {
      const isAdmin = this.$store.getters.role === 'admin'
      const adminMenuList = [
        {
          label: this.$t('navbar.production'),
          path: 'production',
          subMenu: [
            {
              label: this.$t('navbar.productionOrderList'),
              path: '/production/order/list',
              subMenu: []
            },
            {
              label: this.$t('navbar.productionBatchList'),
              path: '/production/batch/list',
              subMenu: []
            }
          ]
        }
      ]
      const userMenuList = []
      return isAdmin ? adminMenuList : userMenuList
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>

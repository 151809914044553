export default {
  zh: {
    // 波次
    createProductionBatch: '创建生产波次',
    editProductionBatch: '修改生产波次',
    createTip1: '请选择需要排产的任务类型',
    createTip2: '该订单有多笔子订单的面料、工艺、尺寸完全相同，是否合并子订单？',
    createMakeSamplesBtn: '打样排产',
    createPrintingBtn: '印花排产',
    confirmProduction: '确认排产',
    finishInfo: '完成：{fabricCount}款面料/{patternCount}张图稿/{totalCount}条/共{totalMeter}米',
    scheduleQuantity: '排产数量',
    finishQuantity: '完成数量',
    confirmCompleted: '确认完成'
  },
  en: {
    // 波次
    createProductionBatch: 'Create Production Batch',
    editProductionBatch: 'Edit Production Batch',
    createMakeSamplesBtn: 'Make Samples',
    createPrintingBtn: 'Printing',
    confirmProduction: 'Confirm Production',
    createTip1: 'Please select the type of task that needs to be scheduled',
    createTip2:
      'The fabric, pattern, and size of the sub-orders in this order are completely identical. Would you like to merge them?',
    finishInfo: 'Completed: {fabricCount} Fabrics/{patternCount} Patterns/{totalCount} Numbers/共{totalMeter} Pcs',
    scheduleQuantity: 'Schedule Quantity',
    finishQuantity: 'Finish Quantity',
    confirmCompleted: 'Confirm Completed'
  }
}

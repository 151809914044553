import request from '@/utils/request'

export default {
  createNewWave(data) {
    const action = data.waveId ? '/fsc/wave/updateWave' : '/fsc/wave/createNew'
    return request({
      url: action,
      method: 'post',
      data
    })
  },
  fetchBatchList(data) {
    return request({
      url: `/fsc/wave/queryList`,
      method: 'post',
      data
    })
  },
  getUnBeginTask(data) {
    const action = data.id ? '/fsc/producerTask/preUpdateWave' : '/fsc/producerTask/getUnBeginTask'
    return request({
      url: action,
      method: 'post',
      data
    })
  },
  getWaveDetail(data) {
    return request({
      url: `/fsc/wave/detail`,
      method: 'post',
      data
    })
  },
  finishWave(data) {
    return request({
      url: '/fsc/wave/finishWave',
      method: 'post',
      data
    })
  },
  fetchWaveTotalCount() {
    return request({
      url: '/fsc/wave/commonCount',
      method: 'post'
    })
  }
}

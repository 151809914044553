<template>
  <div class="header">
    <div class="header-left">
      <router-link class="logo" to="/">
        <img class="feline-logo" :src="felineLogo" alt="" />
        <!-- <span class="logo-text">FELINE Management System</span> -->
      </router-link>
      <nav-bar />
    </div>
    <div class="header-right">
      <lang-select class="right-menu-item hover-effect" />
      <el-divider direction="vertical" />
      <!-- <div class="download-center" @click="$router.push('/download-center')">
        <svg
          t="1660639330023"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6428"
          width="18"
          height="18"
        >
          <path
            d="M768 768q0-14.857143-10.857143-25.714286t-25.714286-10.857143-25.714286 10.857143-10.857143 25.714286 10.857143 25.714286 25.714286 10.857143 25.714286-10.857143 10.857143-25.714286zm146.285714 0q0-14.857143-10.857143-25.714286t-25.714286-10.857143-25.714286 10.857143-10.857143 25.714286 10.857143 25.714286 25.714286 10.857143 25.714286-10.857143 10.857143-25.714286zm73.142857-128l0 182.857143q0 22.857143-16 38.857143t-38.857143 16l-841.142857 0q-22.857143 0-38.857143-16t-16-38.857143l0-182.857143q0-22.857143 16-38.857143t38.857143-16l265.714286 0 77.142857 77.714286q33.142857 32 77.714286 32t77.714286-32l77.714286-77.714286 265.142857 0q22.857143 0 38.857143 16t16 38.857143zm-185.714286-325.142857q9.714286 23.428571-8 40l-256 256q-10.285714 10.857143-25.714286 10.857143t-25.714286-10.857143l-256-256q-17.714286-16.571429-8-40 9.714286-22.285714 33.714286-22.285714l146.285714 0 0-256q0-14.857143 10.857143-25.714286t25.714286-10.857143l146.285714 0q14.857143 0 25.714286 10.857143t10.857143 25.714286l0 256 146.285714 0q24 0 33.714286 22.285714z"
            p-id="6429"
            fill="#b1b1b1"
          ></path>
        </svg>
        <span>{{ $t('download.download') }}</span>
        <i v-if="exsitUnread" class="red-point"></i>
      </div>
      <el-divider direction="vertical" /> -->
      <el-dropdown class="" trigger="click">
        <div class="avatar-wrapper right-menu-item">
          <img v-if="avatar" :src="avatar" class="user-avatar" />
          <span class="">{{ userName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <router-link to="/profile/index">
            <el-dropdown-item>
              {{ $t('navbar.profile') }}
            </el-dropdown-item>
          </router-link> -->
          <el-dropdown-item @click.native="logout">
            <span style="display: block">{{ $t('login.logOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from './NavBar.vue'
import LangSelect from '@/components/LangSelect'
import api from '@/api'

export default {
  components: { NavBar, LangSelect },
  computed: {
    ...mapGetters(['name', 'avatar'])
  },
  data() {
    return {
      userName: localStorage.getItem('userName') || '',
      felineLogo: 'https://feline-prod.oss-cn-hongkong.aliyuncs.com/front/220325%20-%20Feline%20Logo.png',
      exsitUnread: false
    }
  },
  methods: {
    // 退出登陆
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    taskExistUnread() {
      api
        .taskExistUnread()
        .then((res) => {
          this.exsitUnread = res.data
        })
        .catch((e) => {})
    }
  },
  created() {
    // this.taskExistUnread()
    // this.$store.dispatch('user/getInfo', {})
    //   .then((res) => {
    //   })
    //   .catch(() => {
    //   })
  },
  mounted() {
    // this.$EventBus.$on('signTask', () => {
    //   this.taskExistUnread()
    // })
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  min-width: 1440px;
  height: 60px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  font-size: 22px;
  color: #ffffff;
  background-color: #222222;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .header-left {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      img {
        height: 24px;
        margin-right: 10px;
      }
    }
    // .logo-text {
    //   color: #ffffff;
    //   font-weight: bold;
    //   font-size: 18px;
    // }
    .feline-logo {
      // width: 200px;
    }
  }
  .header-right {
    font-size: 16px;
    margin-right: 20px;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    align-items: center;
    .avatar-wrapper {
      // color: white;
      color: rgb(177, 177, 177);
      font-size: 14px;
      cursor: pointer;
    }
    // .user-name {
    //   color: ;
    // }
    // .icon-poweroff {
    //   font-size: 18px;
    //   cursor: pointer;
    //   &:hover {
    //     color: #1890ff;
    //   }
    // }
  }
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  // font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
.download-center {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #b1b1b1;
  .icon {
    margin-right: 4px;
  }
  .red-point {
    border-radius: 50%;
    display: block;
    width: 8px;
    background: red;
    height: 8px;
    position: relative;
    bottom: 4px;
    margin-left: 1px;
  }
}
</style>

import request from '@/utils/request'

export default {
  login(data) {
    return request({
      url: '/fsc/login/doLogin',
      method: 'post',
      data
    })
  },
  createUser(data) {
    return request({
      url: '/user',
      method: 'post',
      data
    })
  },
  updateUser(data) {
    return request({
      url: `/user/${data.userId}`,
      method: 'put',
      data
    })
  },
  fetchUserList(data) {
    // 获取用户列表
    return request({
      url: `/user`,
      method: 'get',
      params: data
    })
  },
  // fetchUserInfo(data) {
  //   return request({
  //     url: `/user/${data.userId}`,
  //     method: 'get',
  //     // params: { token }
  //     data
  //   })
  // },
  // logout() {
  //   return request({
  //     url: '/user',
  //     method: 'get'
  //   })
  // },
  fetchClientManager() {
    return request({
      url: '/user/clientManager',
      method: 'get'
    })
  },
  fetchOwnerList(data) {
    return request({
      url: '/user/client',
      method: 'get',
      params: data
    })
  },
  fetchOwnerListNew(data) {
    return request({
      url: '/fsc/basic/clientList',
      method: 'get',
      params: data
    })
  }
}

import Layout from '@/layout/Layout'

const orderRouter = {
  path: '/order',
  component: Layout,
  name: 'Order',
  redirect: '/order/list',
  meta: {
    title: 'order',
    icon: ''
  },
  children: [
    {
      path: 'create',
      component: () => import('@/views/order/create'),
      name: 'CreateOrder',
      meta: { title: 'createOrder' }
    },
    {
      path: 'list',
      component: () => import('@/views/order/list'),
      name: 'OrderList',
      meta: { title: 'orderList' }
    },
    {
      path: 'sumList',
      component: () => import('@/views/order/sumList'),
      name: 'SumOrderList',
      meta: { title: 'sumOrderList' }
    },
    {
      path: 'detail/:orderId',
      component: () => import('@/views/order/detail'),
      name: 'OrderDetail',
      meta: { title: 'orderDetail' }
    },
    {
      path: 'meprint/:orderId',
      component: () => import('@/views/order/pushOrderToMeprint'),
      name: 'PushOrderToMeprint',
      meta: { title: 'pushOrderToMeprint' }
    },
  ]
}
export default orderRouter

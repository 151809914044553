import request from '@/utils/request'

export default {
  createOrder(data) {
    return request({
      url: '/order',
      method: 'post',
      data
    })
  },
  updateOrder(data) {
    return request({
      url: `/order/${data.orderId}`,
      method: 'put',
      data
    })
  },
  acceptOrder(data) {
    return request({
      url: `/order/accept/${data.orderId}`,
      method: 'put',
      data
    })
  },
  cancelOrder(data) {
    return request({
      url: `/order/cancel/${data.orderId}`,
      method: 'put',
      data
    })
  },
  scheduleOrder(data) {
    return request({
      url: `/order/schedule/${data.orderId}`,
      method: 'put'
    })
  },
  sendOut(data) {
    return request({
      url: `/order/delivery/${data.orderId}`,
      method: 'put',
      data
    })
  },
  signIn(data) {
    return request({
      url: `/order/sign/${data.orderId}`,
      method: 'put',
      data
    })
  },
  fetchOrderInfo(data) {
    return request({
      url: `/order/${data.orderId}`,
      method: 'get',
      params: data
    })
  },
  fetchOrderList(data) {
    // return request({
    //   url: '/order',
    //   method: 'get',
    //   params: data,
    //   timeout: 0
    // })
  },

  // pattern 花型操作
  deletePattern(data) {
    return request({
      url: `/order/sub/pattern/${data.patternId}`,
      method: 'delete',
      data
    })
  },
  createPattern(data) {
    return request({
      url: `/order/sub/pattern`,
      method: 'post',
      data
    })
  },
  updatePattern(data) {
    return request({
      url: `/order/sub/pattern/${data.patternId}`,
      method: 'put',
      data
    })
  },
  fetchPatternPicList(data) {
    /**
     * ownerUId=订单所属客户uid
     * timeType = 1 "过去30天",
     * timeType = 2, "30-90天",
     * timeType = 3, "过去90天";
     */
    return request({
      url: `/order/sub/pattern/pic/list`,
      method: 'get',
      params: data
    })
  },
  // 子单
  createSubOrder(data) {
    return request({
      url: `/order/sub`,
      method: 'post',
      data
    })
  },
  updateSubOrder(data) {
    return request({
      url: `/order/sub/${data.subOrderId}`,
      method: 'put',
      data
    })
  },
  fetchFabricSelectList() {
    return request({
      url: '/factors/fabric/type/list',
      method: 'get'
    })
  },
  fetchProcessSelectList() {
    return request({
      url: '/factors/process/type/list',
      method: 'get'
    })
  },
  // 判断是否有批量导出图稿权限：/permission/check?permissionCode=export_order_pattern
  // 判断是否有下载图稿权限：/permission/check?permissionCode=download_order_pattern
  checkPermissions(data) {
    return request({
      url: '/permission/check',
      method: 'get',
      params: data
    })
  },

  // 订单信息导出excel
  orderExport(data) {
    return request({
      url: `/order/export`,
      method: 'post',
      data
    })
  },
  // 订单发货单导出
  orderDeliExport(data) {
    return request({
      url: `/order/delivery/invoice/export`,
      method: 'post',
      data
    })
  },
  // 米印订单详情
  fetchMiyinOrderDetail(data) {
    return request({
      url: `/order/push/miyin/detail/${data.orderId}`,
      method: 'get'
    })
  },

  // 推送至米印
  pushToMiyinOrder(data) {
    return request({
      url: `/order/push/miyin/${data.orderId}`,
      method: 'put',
      data,
      timeout: 0
    })
  },

  // 查看推送记录快照
  fetchRecordDetail(data) {
    return request({
      url: `log/order/push/${data.orderId}`,
      method: 'get',
      params: data
    })
  },

  // 米印-修改图稿信息
  modifyOrderPattern(data) {
    return request({
      url: `/order/sub/pattern/${data.patternId}`,
      method: 'put',
      data
    })
  },
  // 获取订单推送记录 废弃
  // fetchOrderPushLog(orderId) {
  //   return request({
  //     url: `log/order/push/${orderId}`,
  //     method: 'get'
  //   })
  // },
  // 修改图稿后更新相关信息
  updatePatternInfo(data) {
    return request({
      url: `order/sub/pattern/${data.patternId}`,
      method: 'put',
      data
    })
  },
  // 导出订单汇总
  orderSumExport(data) {
    return request({
      url: `order/summary/export`,
      method: 'post',
      data
    })
  },
  // 订单列表各个状态的数目
  orderStatusCount(data) {
    return request({
      url: `order/status/count`,
      method: 'get',
      params: data
    })
  }
}

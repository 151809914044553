<!-- eslint-disable vue/multi-word-component-names -->
<!--
 * @Author: chenpengmin
 * @Date: 2022-04-07 15:49:46
 * @LastEditTime: 2022-05-26 20:39:20
 * @LastEditors: fangchang
 * @Description: 主框架
 * @Version: v1.0
-->

<template>
  <div class="page-container">
    <Header />
    <breadcrumb id="breadcrumb-container" />
    <div class="main-container">
      <!-- <Sidebar /> -->
      <div>
        <router-view />
        <div class="copyright">Copyright © 2022 FELINE. All rights reserved.</div>
        <el-backtop />
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Header,
    Breadcrumb
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.page-container {
  padding-top: 60px;
  .main-container {
    min-height: 100%;
    overflow: auto;
    transition: margin-left 0.25s;
    position: relative;
    // margin-left: 200px;
    // padding-top: 60px;
    padding: 0 20px;
  }
  .copyright {
    text-align: center;
    color: #606266;
    padding: 20px 0 40px;
  }
}
.breadcrumb-container {
  float: left;
}
</style>

import api from '@/api'
import { getToken, setToken, removeToken, getRole, setRole, removeRole } from '@/utils/auth'
import { resetRouter } from '@/router'
import store from '../index.js'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  role: getRole() // user admin
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_ID: (state, id) => {
    state.userId = id
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, role) => {
    state.role = role
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { userName } = userInfo
    return new Promise((resolve, reject) => {
      api
        .login({ name: userName.trim() })
        .then((response) => {
          const { data } = response
          // const curRole = data.accountType === 0 ? 'admin' : 'user' // 0 内部用户   1 外部用户
          const curRole = 'admin' // 0 内部用户   1 外部用户
          commit('SET_TOKEN', data.token)
          commit('SET_ROLES', curRole)
          localStorage.setItem('userName', data.account.name)
          setToken(data.token)
          setRole(curRole)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     api.fetchUserInfo(state.token).then(response => {
  //       const { data } = response

  //       if (!data) {
  //         reject('Verification failed, please Login again.')
  //       }

  //       const { userName, userId, avatar, introduction } = data

  //       commit('SET_ID', userId)
  //       commit('SET_NAME', userName)
  //       commit('SET_AVATAR', avatar)
  //       commit('SET_INTRODUCTION', introduction)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  async logout({ commit }) {
    return new Promise((resolve) => {
      // api.logout(state.token).then(() => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', '')
      store.dispatch('permission/setRoutes', [])
      localStorage.setItem('userName', '')
      removeToken()
      removeRole()
      resetRouter()
      resolve()
      // }).catch(error => {
      // reject(error)
      // })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', '')
      removeToken()
      removeRole()
      resolve()
    })
  }

  // dynamically modify permissions
  // async changeRole({ commit, dispatch }, role) {
  //   const token = ''

  //   commit('SET_TOKEN', token)
  //   setToken(token)

  //   const { role } = await dispatch('getInfo')

  //   resetRouter()

  //   // generate accessible routes map based on role
  //   const accessRoutes = await dispatch('permission/generateRoutes', role, { root: true })
  //   // dynamically add accessible routes
  //   router.addRoutes(accessRoutes)

  //   // reset visited views and cached views
  //   dispatch('tagsView/delAllViews', null, { root: true })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
